import colors from "./colors";
import Timer from "./Timer";
import React from "react";
import MainContext from "./Context";

export default function Message({ msg }) {
  const { chatMessages } = React.useContext(MainContext);
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setChatMessages((chatMessages) => {
  //       let temp = { ...chatMessages };
  //       delete temp[mid];
  //       return temp;
  //     });
  //   }, 10000);
  // }, []);

  return (
    <div className="message" style={{ display: "flex", gap: 10, alignItems: "center", padding: 7, background: "linear-gradient(to right,#151517,#1b1f25,rgba(27, 31, 37, 0.0) )", maxWidth: "90%", borderBottom: "1px solid", borderBottomColor: "rgba(189, 192, 181, 0.1)", borderTop: "1px solid", borderTopColor: "rgba(189, 192, 181, 0.1)", marginTop: 5, marginBottom: 5 }}>
      <div style={{ display: "flex", gap: 10, alignSelf: "flex-start" }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width={20} height={20} style={{ width: 20, height: 20 }} viewBox="0 0 32 32" version="1.1">
          <path d="M28.106 19.944h-0.85c-0.069-0.019-0.131-0.050-0.2-0.063-1.788-0.275-3.2-1.762-3.319-3.506-0.137-1.95 0.975-3.6 2.787-4.137 0.238-0.069 0.488-0.119 0.731-0.181h0.85c0.056 0.019 0.106 0.050 0.169 0.056 1.65 0.269 2.906 1.456 3.262 3.081 0.025 0.125 0.063 0.25 0.094 0.375v0.85c-0.019 0.056-0.050 0.113-0.056 0.169-0.262 1.625-1.419 2.863-3.025 3.238-0.156 0.038-0.3 0.081-0.444 0.119zM4.081 12.056l0.85 0c0.069 0.019 0.131 0.050 0.2 0.056 1.8 0.281 3.206 1.775 3.319 3.537 0.125 1.944-1 3.588-2.819 4.119-0.231 0.069-0.469 0.119-0.7 0.175h-0.85c-0.056-0.019-0.106-0.050-0.162-0.063-1.625-0.3-2.688-1.244-3.194-2.819-0.069-0.206-0.106-0.425-0.162-0.637v-0.85c0.019-0.056 0.050-0.113 0.056-0.169 0.269-1.631 1.419-2.863 3.025-3.238 0.15-0.037 0.294-0.075 0.437-0.113zM15.669 12.056h0.85c0.069 0.019 0.131 0.050 0.2 0.063 1.794 0.281 3.238 1.831 3.313 3.581 0.087 1.969-1.1 3.637-2.931 4.106-0.194 0.050-0.387 0.094-0.581 0.137h-0.85c-0.069-0.019-0.131-0.050-0.2-0.063-1.794-0.275-3.238-1.831-3.319-3.581-0.094-1.969 1.1-3.637 2.931-4.106 0.2-0.050 0.394-0.094 0.588-0.137z" />
        </svg>
        <div style={{ backgroundColor: "white", padding: "1px 5px 1px 5px", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 600, fontSize: 16 }}>{msg.from.slice(0, 3).toUpperCase()}</div>
        <Timer seconds={10} size={20} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        {/* {msg.image && (
          <img
            src={msg.image}
            alt=""
            style={{ width: size(150, 15), cursor: "pointer", objectFit: "contain", height: "auto" }}
            onClick={() => {
              setImagePreview(msg.image);
            }}
          />
        )} */}
        <div style={{ color: "#88b6e2", fontSize: 16, marginTop: 0 }}>{msg.text}</div>
      </div>
    </div>
  );
}
