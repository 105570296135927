const colors = {
  spacerColor: "#77a9d5",
  darkerSpacer: "#292e36",
  backgroundColor: "#05070b",
  textColor: "#94a8be",
  darkerBackground: "#161c26",
  subText: "#bdc0b5",
  lighterBackground: "#202532",
};

export default colors;
