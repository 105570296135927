import "./App.css";
import Chat from "./Chat";
import React from "react";
import colors from "./colors";
import Cookies from "js-cookie";
import io from "socket.io-client";
import { v4 } from "uuid";
import MainContext from "./Context";
import crypto from "crypto-js";
import ChatRequest from "./ChatRequest";

import Div from "./Div";

function App() {
  const cookie_id = "103d644f-0822-4c4e-b093-22ebcdff8adc";
  const cookie_room = "23d1e28b-14d6-48a6-bea4-193aa99f24ad";
  const ip = "http://118.139.165.83:3001";
  // const ip = "http://192.168.218.12:3001";

  const setCookie = (key, value) => {
    Cookies.set(key, value, { expires: 999999 });
  };

  // function chatID(id1, id2) {
  //   const sortedIds = [id1, id2].sort();
  //   const concatenated = sortedIds.join("");
  //   const hash = crypto.createHash("sha256").update(concatenated).digest("hex");
  //   const uniqueId = `${hash.slice(0, 8)}-${hash.slice(8, 12)}-${hash.slice(12, 16)}-${hash.slice(16, 20)}-${hash.slice(20, 32)}`;

  //   return uniqueId;
  // }

  function copyToClipboard(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);

    try {
      const successful = document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy text to clipboard.", err);
    }
    document.body.removeChild(textarea);
  }

  const [userID, setUserID] = React.useState(() => {
    const existingID = Cookies.get(cookie_id);
    if (existingID) {
      return existingID;
    } else {
      const new_id = v4();
      setCookie(cookie_id, new_id);
      return new_id;
    }
  });
  const [roomID, setRoomID] = React.useState(() => {
    const existingID = Cookies.get(cookie_room);
    if (existingID) {
      return existingID;
    } else {
      const new_id = v4();
      setCookie(cookie_room, new_id);
      return new_id;
    }
  });

  const [landscape, setLandscape] = React.useState(window.innerWidth > window.innerHeight);

  const isMobile = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const size = (size, phoneSize) => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? (landscape ? size : phoneSize) : size;
  };

  function isID(id) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
  }

  function checkOrientation() {
    if (window.innerWidth > window.innerHeight) {
      setLandscape(true);
    } else {
      setLandscape(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", checkOrientation);
  }, []);

  const [topPage, setTopPage] = React.useState("private");
  const [imagePreview, setImagePreview] = React.useState();
  const [socket, setSocket] = React.useState();
  const [chatsRequests, setChatsRequests] = React.useState({});
  const [chatMessages, setChatMessages] = React.useState({});

  React.useEffect(() => {
    if (!socket) {
      const socketio = io(ip, {
        query: {
          id: userID,
          roomID: roomID,
        },
      });

      socketio.on("connect", () => {
        console.log("Connected to server");
        setSocket(socketio);
      });

      socketio.on("private_invite", (msg) => {
        setChatsRequests((chatsRequests) => ({ ...chatsRequests, [othersID(msg.id1, msg.id2)]: { type: "private", accepted: false, direction: "income" } }));
      });

      socketio.on("private_accept", (msg) => {
        setChatsRequests((chatsRequests) => ({ ...chatsRequests, [othersID(msg.id1, msg.id2)]: { ...chatsRequests[othersID(msg.id1, msg.id2)], accepted: true } }));
      });

      socketio.on("public_invite", (msg) => {
        setChatsRequests((chatsRequests) => ({ ...chatsRequests, [msg.roomID]: { type: "public", accepted: false, direction: "income" } }));
      });

      socketio.on("public_accept", (msg) => {
        setChatsRequests((chatsRequests) => ({ ...chatsRequests, ["_" + msg.id]: { ...chatsRequests["_" + msg.id], accepted: true } }));
      });

      socketio.on("chatMessage", (msg) => {
        setChatMessages((chatMessages) => ({
          ...chatMessages,
          [msg.type == "private" ? othersID(msg.from, msg.chat) : msg.chat]: { ...chatMessages?.[msg.type == "private" ? othersID(msg.from, msg.chat) : msg.chat], [msg.msgid]: { ...chatMessages[msg.type == "private" ? othersID(msg.from, msg.chat) : msg.chat]?.[msg.msgid], ...msg } },
        }));
      });
    }

    return () => {
      if (socket) {
        socket.off("connect");

        socket.disconnect();
      }
    };
  }, [socket]);

  function calculateDistanceBetweenElements(element1, element2) {
    const element1Bottom = element1.getBoundingClientRect().bottom;
    const element2Top = element2.getBoundingClientRect().top;
    const distance = element2Top - element1Bottom;
    return distance;
  }

  const [privateInvite, setPrivateInvite] = React.useState("");
  const [publicInvite, setPublicInvite] = React.useState("");

  const [currentChat, setCurrentChat] = React.useState();

  const othersID = (id1, id2) => {
    return id1 == userID ? id2 : id1;
  };

  const [maxRequestsHeight, setMaxRequestsHeight] = React.useState(0);

  React.useEffect(() => {
    setMaxRequestsHeight(calculateDistanceBetweenElements(document.getElementById("top-seperator"), document.getElementById("myroom-button")));
  }, []);

  return (
    <MainContext.Provider value={{ roomID, setChatsRequests, chatsRequests, socket, userID, setCurrentChat, currentChat, size, chatMessages, setChatMessages }}>
      {imagePreview && (
        <>
          <Div style={{ width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", backgroundColor: "white", position: "fixed", top: 20, left: 20, zIndex: 9999, cursor: "pointer" }} onClick={() => setImagePreview(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
              <g id="Menu / Close_LG">
                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>
          </Div>
          <Div style={{ position: "fixed", top: 0, zIndex: 999, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.8)" }} onClick={() => setImagePreview(false)}></Div>

          <Div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "85%", height: "85%", backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1000 }}>
            <img src={imagePreview} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
          </Div>
        </>
      )}
      {currentChat && <Chat />}
      <Div id="main-wrapper">
        <Div id={isMobile() && landscape ? "title_mobile_landscape_none" : "title"}>BLACKSPACE</Div>
        <Div id="main-screen">
          <Div id="main-left" style={{ position: "relative" }}>
            <Div id="main-left-top">
              <Div style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 5 }}>
                <Div className="button" style={{ fontSize: 12, borderColor: colors.spacerColor, borderStyle: topPage == "private" ? "solid" : "" }} onClick={() => setTopPage("private")}>
                  PRIVATE
                </Div>
                <Div className="button" style={{ fontSize: 12, borderColor: colors.spacerColor, borderStyle: topPage == "room" ? "solid" : "" }} onClick={() => setTopPage("room")}>
                  PUBLIC
                </Div>
              </Div>
              {topPage == "private" ? (
                <Div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 4 }}>
                  <Div style={{ color: "#bdc0b5", fontSize: 15 }}>Your private ID:</Div>
                  <Div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <Div style={{ color: "#94a8be", fontSize: 14, textAlign: "center" }}>{userID}</Div>
                  </Div>
                  <Div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <Div className="button" style={{ fontSize: 12 }} onClick={() => copyToClipboard(userID)}>
                      Copy
                    </Div>
                    <Div
                      className="button"
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        const new_id = v4();
                        setCookie(cookie_id, new_id);
                        setUserID(new_id);
                        setTimeout(() => {
                          window.location.reload(true);
                        }, 100);
                      }}
                    >
                      Change
                    </Div>
                  </Div>
                  <Div style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 5 }}>
                    <Div divtype="input" onChange={(e) => setPrivateInvite(e.target.value)} value={privateInvite} className="input" placeholder="Invite user to private chat..." style={{ fontSize: 12 }} />
                    <Div
                      className="button"
                      style={{ fontSize: 12, backgroundColor: privateInvite.length && isID(privateInvite) && !chatsRequests[privateInvite] ? "" : "#292b2e", color: privateInvite.length && isID(privateInvite) && !chatsRequests[privateInvite] ? "" : "#414a54" }}
                      onClick={() => {
                        if (privateInvite.length && isID(privateInvite) && !chatsRequests[privateInvite] && privateInvite != userID) {
                          socket.emit("private_invite", { id1: userID, id2: privateInvite, type: "private" });
                          setChatsRequests((chatsRequests) => ({ ...chatsRequests, [privateInvite]: { type: "private", accepted: false, direction: "outcome" } }));
                          setPrivateInvite("");
                        }
                      }}
                    >
                      Invite
                    </Div>
                  </Div>
                </Div>
              ) : (
                <Div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 4 }}>
                  <Div style={{ color: "#bdc0b5", fontSize: 15 }}>Your public room ID:</Div>
                  <Div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <Div style={{ color: "#94a8be", fontSize: 14, textAlign: "center" }}>{roomID}</Div>
                  </Div>
                  <Div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <Div className="button" style={{ fontSize: 12 }} onClick={() => copyToClipboard(roomID)}>
                      Copy
                    </Div>
                    <Div
                      className="button"
                      style={{ fontSize: 12, padding: 5 }}
                      onClick={() => {
                        const new_id = v4();
                        setCookie(cookie_room, new_id);
                        setRoomID(new_id);
                        setTimeout(() => {
                          window.location.reload(true);
                        }, 100);
                      }}
                    >
                      Change
                    </Div>
                  </Div>
                  <Div style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 5 }}>
                    <Div divtype="input" onChange={(e) => setPublicInvite(e.target.value)} value={publicInvite} className="input" placeholder="Invite user to your room..." style={{ padding: 5, fontSize: 12 }} />
                    <Div
                      className="button"
                      style={{ padding: 5, fontSize: 12, backgroundColor: publicInvite.length && isID(publicInvite) && publicInvite != userID && !chatsRequests["_" + publicInvite] ? "" : "#292b2e", color: publicInvite.length && isID(publicInvite) && publicInvite != userID && !chatsRequests["_" + publicInvite] ? "" : "#414a54" }}
                      onClick={() => {
                        // if (publicInvite.length && isID(publicInvite) && publicInvite != userID && !chatsRequests[publicInvite] && (!chatsRequests[publicInvite] || chatsRequests[publicInvite].type != "public")) {
                        if (publicInvite.length && isID(publicInvite) && publicInvite != userID && !chatsRequests["_" + publicInvite]) {
                          socket.emit("public_invite", { id1: userID, id2: publicInvite, roomID: roomID, type: "public" });
                          setChatsRequests((chatsRequests) => ({ ...chatsRequests, ["_" + publicInvite]: { type: "public", accepted: false, direction: "outcome" } }));
                          setPublicInvite("");
                        }
                      }}
                    >
                      Invite
                    </Div>
                  </Div>
                </Div>
              )}
            </Div>
            <Div id="top-seperator" style={{ backgroundColor: "#65686c", height: 2, width: "100%" }} />
            <Div id="chat-requests" style={{ maxHeight: maxRequestsHeight - 5 }}>
              {Object.keys(chatsRequests).map((c) => (
                <ChatRequest c={c} key={c} />
              ))}
            </Div>
            <Div
              id="myroom-button"
              className="button"
              style={{ position: "absolute", bottom: 10, padding: 20, paddingRight: 50, paddingLeft: 50, left: "50%", transform: "translateX(-50%)", backgroundColor: "#1a68ad", borderColor: colors.spacerColor, borderWidth: 5, borderRadius: 15, borderStyle: "solid", whiteSpace: "nowrap" }}
              onClick={() => {
                setCurrentChat({ chat: roomID, type: "public" });
              }}
            >
              My Room
            </Div>
            {/* <Div style={{ backgroundColor: "#65686c", height: 2, width: "100%" }} />
            <Div
              style={{
                padding: 5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Div style={{ color: "rgba(255, 255, 255, 0.1)", fontSize: 10 }}>BlackSpace V0.1 beta</Div>

              <Div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSketch="http://www.bohemiancoding.com/sketch/ns" width="20px" height="20px" viewBox="0 0 30 30" version="1.1">
                  <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" sketchType="MSPage">
                    <g id="Icon-Set-Filled" sketchType="MSLayerGroup" transform="translate(-103.000000, -362.000000)" fill="#94a8be">
                      <path
                        d="M118,382 C115.261,382 113.042,379.762 113.042,377 C113.042,374.238 115.261,372 118,372 C120.739,372 122.959,374.238 122.959,377 C122.959,379.762 120.739,382 118,382 L118,382 Z M132.008,380.536 L129.685,379.184 C129.815,378.474 129.901,377.749 129.901,377 C129.901,376.252 129.815,375.526 129.685,374.816 L132.008,373.464 C132.957,372.912 133.281,371.688 132.733,370.732 L130.75,367.268 C130.203,366.312 128.989,365.983 128.041,366.536 L125.694,367.901 C124.598,366.961 123.352,366.192 121.967,365.697 L121.967,364 C121.967,362.896 121.079,362 119.983,362 L116.017,362 C114.921,362 114.033,362.896 114.033,364 L114.033,365.697 C112.648,366.192 111.402,366.961 110.306,367.901 L107.959,366.536 C107.011,365.983 105.797,366.312 105.25,367.268 L103.267,370.732 C102.719,371.688 103.044,372.912 103.992,373.464 L106.315,374.816 C106.185,375.526 106.099,376.252 106.099,377 C106.099,377.749 106.185,378.474 106.315,379.184 L103.992,380.536 C103.044,381.088 102.719,382.312 103.267,383.268 L105.25,386.732 C105.797,387.688 107.011,388.017 107.959,387.464 L110.306,386.099 C111.402,387.039 112.648,387.809 114.033,388.303 L114.033,390 C114.033,391.104 114.921,392 116.017,392 L119.983,392 C121.079,392 121.967,391.104 121.967,390 L121.967,388.303 C123.352,387.809 124.598,387.039 125.694,386.099 L128.041,387.464 C128.989,388.017 130.203,387.688 130.75,386.732 L132.733,383.268 C133.281,382.312 132.957,381.088 132.008,380.536 L132.008,380.536 Z M118,374 C116.357,374 115.025,375.344 115.025,377 C115.025,378.657 116.357,380 118,380 C119.643,380 120.975,378.657 120.975,377 C120.975,375.344 119.643,374 118,374 L118,374 Z"
                        id="settings"
                        sketchType="MSShapeGroup"
                      />
                    </g>
                  </g>
                </svg>
              </Div>
            </Div> */}
          </Div>
        </Div>
      </Div>
    </MainContext.Provider>
  );
}

export default App;
