import React from "react";
import MainContext from "./Context";
import colors from "./colors";
import Timer from "./Timer";
import PreviewMessage from "./PreviewMessage";

import Div from "./Div";

export default function ChatRequest({ c }) {
  const { chatsRequests, setChatsRequests, socket, userID, setCurrentChat, roomID } = React.useContext(MainContext);

  const [accepted, setAccepted] = React.useState();
  const [killTimer, setKillTimer] = React.useState();

  React.useEffect(() => {
    if (chatsRequests[c].accepted) {
      clearTimeout(killTimer);
      setAccepted(true);
    }
  }, [chatsRequests]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (!accepted) {
        setChatsRequests((chatsRequests) => {
          let temp = { ...chatsRequests };
          delete temp[c];
          return temp;
        });
      }
    }, 20000);

    setKillTimer(timer);
  }, [accepted]);

  return (
    <Div className="chat-request">
      <Div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <Div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Div style={{ fontSize: 14, color: "#94a8be" }}>{c.replace(/_/g, "")}</Div>
          <Div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            {chatsRequests[c].direction == "income" ? (
              <Div className="income" style={{ fontSize: 10 }}>
                INCOME
              </Div>
            ) : (
              <Div className="outcome" style={{ fontSize: 10 }}>
                OUTCOME
              </Div>
            )}
            {chatsRequests[c].type == "private" ? (
              <Div className="private" style={{ fontSize: 10 }}>
                PRIVATE
              </Div>
            ) : (
              <Div className="public" style={{ fontSize: 10 }}>
                PUBLIC
              </Div>
            )}
            {!chatsRequests[c].accepted && <Timer seconds={20} />}

            <PreviewMessage c={c} />
          </Div>
        </Div>
      </Div>
      {!chatsRequests[c].accepted && chatsRequests[c].direction == "income" && (
        <Div
          className="button"
          style={{ fontSize: 14 }}
          onClick={() => {
            if (chatsRequests[c].type == "private") {
              socket.emit("private_accept", { id1: c, id2: userID, accepted: true });
              setChatsRequests((chatsRequests) => ({ ...chatsRequests, [c]: { ...chatsRequests[c], accepted: true } }));
            } else {
              socket.emit("public_accept", { roomID: c, id: userID, accepted: true });
              setChatsRequests((chatsRequests) => ({ ...chatsRequests, [c]: { ...chatsRequests[c], accepted: true } }));
            }
          }}
        >
          Accept
        </Div>
      )}
      {chatsRequests[c].accepted && (
        <>
          {(chatsRequests[c].type == "public" && chatsRequests[c].direction != "outcome") ||
            (chatsRequests[c].type == "private" && (
              <Div
                className="button"
                style={{ fontSize: 14 }}
                onClick={() => {
                  setCurrentChat({ chat: c, type: chatsRequests[c].type });
                }}
              >
                Enter
              </Div>
            ))}
        </>
      )}
      {!chatsRequests[c].accepted && chatsRequests[c].direction == "outcome" && <Div style={{ color: "#94a8be", padding: 4.5, fontSize: 12 }}>Waiting...</Div>}
      {chatsRequests[c].accepted && chatsRequests[c].direction == "outcome" && chatsRequests[c].type == "public" && (
        <Div divtype="svg" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 60 60">
          <path className="cls-1" fill={colors.spacerColor} fillRule={"evenodd"} d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" id="check" transform="translate(-770 -450)" />
        </Div>
      )}
    </Div>
  );
}
