import colors from "./colors";
import Div from "./Div";
import React from "react";
import MainContext from "./Context";

export default function PreviewMessage({ c }) {
  const { chatMessages } = React.useContext(MainContext);

  const [lastMessage, setLastMessage] = React.useState();
  React.useEffect(() => {
    if (chatMessages[c]) {
      const messages = chatMessages[c];
      const sortedMessages = Object.entries(messages).sort(([, a], [, b]) => a.timestamp - b.timestamp); // Sort by timestamp
      const [lastMessageId, lastMessageObject] = sortedMessages[sortedMessages.length - 1];
      setLastMessage(lastMessageObject);
    }
  }, [chatMessages]);

  if (chatMessages[c] && lastMessage)
    return (
      <Div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <Div divtype="svg" xmlns="http://www.w3.org/2000/svg" style={{ width: 15, height: 15 }} width={15} height={15} viewBox="0 0 16 16" fill="none">
          <path d="M8 6L8 2L10 2L16 8L10 14L8 14L8 10L-1.74845e-07 10L-3.01991e-07 6L8 6Z" fill={colors.spacerColor} />
        </Div>
        <Div style={{ backgroundColor: "white", padding: "1px 5px 1px 5px", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 600, fontSize: 12 }}>{lastMessage.from.slice(0, 3).toUpperCase()}</Div>
        <Div style={{ color: colors.spacerColor, fontSize: 14 }}>{lastMessage.text}</Div>
      </Div>
    );
}
