import React from "react";
import MainContext from "./Context";

const Div = React.forwardRef(function (props, ref) {
  const { size } = React.useContext(MainContext);

  const styles = {
    fontSize: 2.5,
    gap: 3,

    margin: 3,
    marginTop: 3,
    marginBottom: 3,
    marginLeft: 3,
    marginRight: 3,

    padding: 2,
    paddingRight: 2,
    paddingLeft: 2,
    paddingBottom: 2,
    paddingTop: 2,

    bottom: 3,
    top: 3,

    width: 2.5,
    height: 2.5,
  };

  let propss = { ...props };

  if (props.style) {
    if (!props.style.fontSize) props.style.fontSize = 16;
    if (props.className) {
      if (props.className.includes("input") || props.className.includes("button")) {
        if (!props.style.padding) props.style.padding = 5;
      }
    }
    for (let s of Object.keys(styles)) {
      if (s == "top" && props.style.top == "50%") continue;
      if (s == "bottom" && props.style.bottom == "50%") continue;
      if (s == "left" && props.style.left == "50%") continue;
      if (s == "right" && props.style.right == "50%") continue;
      if (s == "width" && (typeof props.width == "string" || typeof props.style.width == "string")) continue;
      if (s == "height" && (typeof props.height == "string" || typeof props.style.height == "string")) continue;

      if (props.style[s]) {
        propss.style[s] = size(props.style[s], props.style[s] * styles[s]);
      }
      if (props[s]) {
        propss[s] = size(props[s], props[s] * styles[s]);
      }
    }
  }

  if (props.divtype == "input") {
    return <input ref={ref} {...propss} />;
  }

  if (props.divtype == "svg") {
    return <svg ref={ref} {...propss} />;
  }
  return <div ref={ref} {...propss}></div>;
});

export default Div;
