import colors from "./colors";
import { v4 } from "uuid";
import Message from "./Message";
import React from "react";
import MainContext from "./Context";
import Div from "./Div";

export default function Chat() {
  const { socket, userID, currentChat, setCurrentChat, chatMessages, setChatMessages } = React.useContext(MainContext);
  const [message, setMessage] = React.useState("");
  const fileInputRef = React.useRef();
  const fileInputRef2 = React.useRef();
  const [image, setImage] = React.useState();

  const messagesEndRef = React.useRef();
  const messagesRef = React.useRef();

  React.useEffect(
    () => {
      const elementRect = messagesEndRef.current.getBoundingClientRect();
      const containerRect = messagesRef.current.getBoundingClientRect();
      const containerScrollTop = messagesRef.current.scrollTop;
      const containerScrollBottom = containerScrollTop + messagesRef.current.clientHeight;
      const elementTop = elementRect.top - containerRect.top + containerScrollTop;
      const elementBottom = elementTop + elementRect.height;

      if (elementBottom > containerScrollTop && elementTop < containerScrollBottom + 100) {
        messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
      }
    },
    [
      /*chatMessages*/
    ]
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "97%",
          borderRadius: 10,
          height: "97%",
          maxHeight: "97%",
          zIndex: 100,
          background: "linear-gradient(to right, #000000, #070a11, #000000)",
          boxShadow: "rgba(119, 169, 213, 0.25) 0px 54px 55px, rgba(119, 169, 213, 0.12) 0px -12px 30px, rgba(119, 169, 213, 0.12) 0px 4px 6px, rgba(119, 169, 213, 0.17) 0px 12px 13px, rgba(119, 169, 213, 0.09) 0px -3px 5px",
        }}
      >
        {/* ========================Top Section========================*/}
        <Div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", background: "linear-gradient(to right, rgba(21, 25, 31, 0.5), rgba(31, 39, 50, 0.5), rgba(21, 25, 31, 0.5))", borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 5, paddingRight: 10, paddingLeft: 10, borderBottom: "5px solid", borderBottomColor: colors.spacerColor }}>
          <Div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Div className="spinner" style={{ width: 15, height: 15 }}>
              <Div className="spinner-rotate"></Div>
              <Div className="spinner-center"></Div>
            </Div>
            <Div style={{ display: "flex", gap: 4, transform: "scaleY(1.3)" }}>
              <Div style={{ fontSize: 18, color: "white", fontFamily: "Courier New" }}>BLACK</Div>
              <Div style={{ fontSize: 18, color: "white", fontFamily: "Courier New" }}>SPACE</Div>
            </Div>
          </Div>
          <Div style={{ display: "flex", alignItems: "center", gap: 15, paddingRight: 5, paddingLeft: 5 }}>
            <Div divtype="svg" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" style={{ width: 20, height: 20 }} width={20} height={20} viewBox="0 0 32 32" version="1.1">
              <path d="M28.106 19.944h-0.85c-0.069-0.019-0.131-0.050-0.2-0.063-1.788-0.275-3.2-1.762-3.319-3.506-0.137-1.95 0.975-3.6 2.787-4.137 0.238-0.069 0.488-0.119 0.731-0.181h0.85c0.056 0.019 0.106 0.050 0.169 0.056 1.65 0.269 2.906 1.456 3.262 3.081 0.025 0.125 0.063 0.25 0.094 0.375v0.85c-0.019 0.056-0.050 0.113-0.056 0.169-0.262 1.625-1.419 2.863-3.025 3.238-0.156 0.038-0.3 0.081-0.444 0.119zM4.081 12.056l0.85 0c0.069 0.019 0.131 0.050 0.2 0.056 1.8 0.281 3.206 1.775 3.319 3.537 0.125 1.944-1 3.588-2.819 4.119-0.231 0.069-0.469 0.119-0.7 0.175h-0.85c-0.056-0.019-0.106-0.050-0.162-0.063-1.625-0.3-2.688-1.244-3.194-2.819-0.069-0.206-0.106-0.425-0.162-0.637v-0.85c0.019-0.056 0.050-0.113 0.056-0.169 0.269-1.631 1.419-2.863 3.025-3.238 0.15-0.037 0.294-0.075 0.437-0.113zM15.669 12.056h0.85c0.069 0.019 0.131 0.050 0.2 0.063 1.794 0.281 3.238 1.831 3.313 3.581 0.087 1.969-1.1 3.637-2.931 4.106-0.194 0.050-0.387 0.094-0.581 0.137h-0.85c-0.069-0.019-0.131-0.050-0.2-0.063-1.794-0.275-3.238-1.831-3.319-3.581-0.094-1.969 1.1-3.637 2.931-4.106 0.2-0.050 0.394-0.094 0.588-0.137z" />
            </Div>
            <Div
              divtype="svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width={20}
              height={20}
              viewBox="0 -0.5 8 8"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // socket.emit("leaveChat", { from: id, id: currentChat });
                setCurrentChat(false);
              }}
            >
              <defs></defs>
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -206.000000)" fill="#FFFFFF">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <polygon id="close_mini-[#1522]" points="334.6 49.5 337 51.6 335.4 53 333 50.9 330.6 53 329 51.6 331.4 49.5 329 47.4 330.6 46 333 48.1 335.4 46 337 47.4"></polygon>
                  </g>
                </g>
              </g>
            </Div>
          </Div>
        </Div>
        {/* =========================================================== */}
        <Div id="chat-top-info" style={{ backgroundColor: "rgba(119, 169, 213, 0.05)", width: "100%", padding: 10, paddingRight: 15, paddingLeft: 15, borderBottom: "1px solid", borderBottomColor: "rgba(189, 192, 181, 0.2)" }}>
          <Div style={{ display: "flex", gap: 5 }}>
            <Div style={{ color: "white", fontSize: 16 }}>Room: </Div>
            <Div style={{ color: colors.subText, fontSize: 16 }}>{currentChat.chat.slice(0, 6).toUpperCase()}</Div>
          </Div>
        </Div>
        {/* =========================================================== */}

        {/* Messages Section */}

        <Div ref={messagesRef} id="chat-messages" style={{ display: "flex", flexDirection: "column", overflowY: "auto", flexGrow: 1 }}>
          {chatMessages[currentChat.chat] &&
            Object.keys(chatMessages[currentChat.chat])
              .sort((a, b) => a.timestamp - b.timestamp)
              .map((m) => <Message key={m} msg={chatMessages[currentChat.chat][m]} />)}
          <Div ref={messagesEndRef}></Div>
        </Div>

        {/* Bottom Section */}
        <Div id="chat-bottom" style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {image && (
            <Div style={{ width: "50%", height: "50%", alignSelf: "center", position: "relative" }}>
              <Div
                divtype="svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="25px"
                height="25px"
                viewBox="0 -0.5 8 8"
                style={{ cursor: "pointer", position: "absolute", top: 10, right: 10, backgroundColor: colors.lighterBackground, padding: 5, borderRadius: "50%" }}
                onClick={() => {
                  setImage(false);
                }}
              >
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -206.000000)" fill={colors.spacerColor}>
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                      <polygon id="close_mini-[#1522]" points="334.6 49.5 337 51.6 335.4 53 333 50.9 330.6 53 329 51.6 331.4 49.5 329 47.4 330.6 46 333 48.1 335.4 46 337 47.4"></polygon>
                    </g>
                  </g>
                </g>
              </Div>
              <img src={image} alt="" style={{ width: "100%" }} />
            </Div>
          )}
          <Div style={{ display: "flex", gap: 15, alignItems: "center", paddingLeft: 10, paddingRight: 10, paddingBottom: 5 }}>
            <Div style={{ background: "linear-gradient(to right,#151517,#1b1f25,#151517 )", paddingTop: 5, width: "95%", alignSelf: "center", borderRadius: 20 }}>
              <Div
                placeholder="Write a message..."
                id="chat-input-text"
                style={{ background: "transparent", color: colors.subText, fontSize: 16, width: "100%", padding: 14, paddingTop: 10, maxHeight: 117.6, minHeight: 26.46 }}
                contentEditable
                onInput={(e) => {
                  setMessage(e.target.innerText);
                }}
                onKeyDown={(e) => {
                  if (e.code == "Enter" && !e.shiftKey) {
                    let text = message.trimStart().trimEnd();
                    if (text.length > 0 || image) {
                      e.preventDefault();
                      setMessage("");
                      e.target.innerHTML = "";
                      const image_temp = image;
                      socket.emit("chatMessage", { ...currentChat, msgid: v4(), timestamp: Date.now(), from: userID, text: text, image: image_temp });
                      setImage(false);
                    }
                  }
                }}
              ></Div>
            </Div>
            <Div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              {/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !message.length && (
                <Div
                  divtype="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: 30, height: 30, marginRight: 4, marginLeft: 4 }}
                  viewBox="0 0 16 16"
                  fill="none"
                  onClick={() => {
                    fileInputRef2.current.click();
                  }}
                >
                  <path fillRule="evenodd" clipRule="evenodd" d="M3 3H0V14H16V3H13L11 1H5L3 3ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" fill={colors.spacerColor} />
                </Div>
              )}
              {!message.length && (
                <Div
                  divtype="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <path fillRule="evenodd" clipRule="evenodd" d="M1 1H15V15H1V1ZM6 9L8 11L13 6V13H3V12L6 9ZM6.5 7C7.32843 7 8 6.32843 8 5.5C8 4.67157 7.32843 4 6.5 4C5.67157 4 5 4.67157 5 5.5C5 6.32843 5.67157 7 6.5 7Z" fill={colors.spacerColor} />
                </Div>
              )}

              <Div
                divtype="svg"
                xmlns="http://www.w3.org/2000/svg"
                width={50}
                height={50}
                viewBox="0 0 24 24"
                fill="none"
                style={{ cursor: "pointer", marginLeft: 0, marginRight: 0 }}
                onClick={() => {
                  let text = message.trimStart().trimEnd();
                  if (text.length > 0 || image) {
                    setMessage("");
                    document.getElementById("chat-input-text").innerHTML = "";
                    const image_temp = image;
                    socket.emit("chatMessage", { ...currentChat, msgid: v4(), timestamp: Date.now(), from: userID, text: text, image: image_temp });
                    setImage(false);
                    document.getElementById("chat-input-text").focus();
                  }
                }}
              >
                <path
                  d="M6.99811 10.2467L7.43298 11.0077C7.70983 11.4922 7.84825 11.7344 7.84825 12C7.84825 12.2656 7.70983 12.5078 7.43299 12.9923L7.43298 12.9923L6.99811 13.7533C5.75981 15.9203 5.14066 17.0039 5.62348 17.5412C6.1063 18.0785 7.24961 17.5783 9.53623 16.5779L15.8119 13.8323C17.6074 13.0468 18.5051 12.654 18.5051 12C18.5051 11.346 17.6074 10.9532 15.8119 10.1677L9.53624 7.4221C7.24962 6.42171 6.1063 5.92151 5.62348 6.45883C5.14066 6.99615 5.75981 8.07966 6.99811 10.2467Z"
                  fill={colors.spacerColor}
                />
              </Div>
            </Div>
          </Div>
        </Div>

        <input type="file" accept="image/*" multiple={false} ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
        {/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && <input type="file" accept="image/*" capture="environment" multiple={false} ref={fileInputRef2} style={{ display: "none" }} onChange={handleFileChange} />}
      </Div>
    </>
  );
}
